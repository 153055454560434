import axios from 'axios';
import { CMS_URL, API_KEY } from '@/config';

const apiClient = axios.create({
  baseURL: CMS_URL,
  headers: {
    'x-api-key': API_KEY,
  },
});

const getStaticPagesCategories = () => {
  return apiClient.get(`/static-pages/category/public`);
};

const getStaticPageBySlug = (slug) => {
  return apiClient.get(`/static-pages/slug/${slug}`);
};

export default {
  getStaticPagesCategories,
  getStaticPageBySlug,
};
