<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <a class="navbar-item" href="https://axsamerica.com">
      <img
        src="../assets/logo-axs-america-R_KO-horz.19fc671b 1.svg"
        alt="AXS AMERICA"
      />
    </a>
  </nav>
</template>
