<template>
  <!-- header -->
  <article>
    <!-- hero -->
    <section id="customer" class="hero">
      <div class="container">
        <div class="hero-body">
          <div id="hero-left" class="column">
            <p id="coming-soon">Coming soon!</p>
            <p id="about">
              Support American Dreams: Your Purchase Powers Our Nation's Businesses
            </p>
            <div id="interest-signup-container">
              <p id="interest-signup">
                Sign up now to receive notifications as soon as we launch, and
                stay updated on all the latest developments and announcements
              </p>
              <div id="customer-input-wrapper">
                <input
                  class="input is-large"
                  type="text"
                  v-model="customerEmail"
                  @focus="customerError = false"
                  @blur="customerError = false"
                  placeholder="Enter your email address"
                />
                <button v-on:click="submitCustomer" class="button">
                  Notify Me
                </button>
              </div>
              <transition name="customerErrorTransition">
                <p v-if="customerError" id="customer-error">
                  <span v-if="errorMessage">
                    {{ errorMessage }}
                  </span>
                </p>
              </transition>
            </div>
            <p id="anti-spam">* Don't worry, we will not spam you :)</p>
          </div>
        </div>
      </div>
    </section>

    <!-- seller section -->
    <section id="seller" class="hero">
      <div class="hero-body">
        <div class="column is-three-fifths">
          <img src="../assets/Untitled-1 1.svg" alt="" />
        </div>

        <div id="seller-column" class="column">
          <p id="seller-text">
            Connect with Axs America: Your gateway to America's commitment to American businesses
          </p>
          <p id="seller-description">
            Become a distinguished supplier on Axs America and join our 
            thriving community dedicated to showcasing American 
            excellence. As a premier destination for fostering business 
            connections across the country, Axs America offers unparalleled 
            opportunities to amplify your visibility, boost your sales, and 
            engage with a wide network of buyers eager for quality American 
            products. Elevate your business and be part of a movement 
            championing American commerce. Reach out today to explore 
            how we can drive your growth on our dynamic ecommerce 
            platform, where every transaction celebrates and supports 
            American enterprise.
          </p>
          <button v-on:click="modal = !modal" class="button">Contact us now</button>
        </div>
      </div>
    </section>

    <div class="modal" id="form" v-bind:class="{ 'is-active': modal }">
      <div class="modal-background" v-on:click="modal = !modal"></div>
      <div class="modal-content">
        <div class="form-wrap">
          <p id="form-title">Join Our Seller Community</p>
          <p id="form-text">
            Sign up now to request access to our marketplace as a source vendor and be
            notified of our launch schedule and next steps
          </p>

          <div class="columns">
            <div class="column">
              <label for="firstName">First Name</label>
              <input
                class="input"
                type="text"
                name="firstName"
                v-model="firstName"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.firstName }}
              </p>
            </div>
            <div class="column">
              <label for="lastName">Last Name</label>
              <input
                class="input"
                type="text"
                label="lastName"
                v-model="lastName"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.lastName }}
              </p>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label for="sellerEmail">Email</label>
              <input
                class="input"
                type="text"
                name="sellerEmail"
                v-model="sellerEmail"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.email }}
              </p>
            </div>
            <div class="column">
              <label for="phone">Phone number</label>
              <input
                @input="formatPhone"
                class="input"
                type="tel"
                name="phone"
                v-model="phone"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.phone }}
              </p>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label for="businessName">Legal Business name</label>
              <input
                class="input"
                type="text"
                name="businessName"
                v-model="businessName"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.legalBusinessName }}
              </p>
            </div>
            <div class="column">
              <label for="dba">DBA</label>
              <input class="input" name="dba" type="text" v-model="dba" />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.dba }}
              </p>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label for="address1">Address 1</label>
              <input
                class="input"
                type="text"
                name="address1"
                v-model="address1"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.line1 }}
              </p>
            </div>
            <div class="column">
              <label for="address2">Address 2</label>
              <input
                class="input"
                type="text"
                name="address2"
                v-model="address2"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.line2 }}
              </p>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label for="city">City</label>
              <input class="input" type="text" name="city" v-model="city" />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.city }}
              </p>
            </div>
            <div class="column">
              <label for="state">State</label>
              <input
                class="input"
                type="text"
                name="state"
                v-model="state"
                maxLength="2"
              />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.state }}
              </p>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <label for="zip">Zip Code</label>
              <input class="input" type="text" v-model="zip" />
              <p v-show="sellerError" id="seller-error">
                {{ errorArr.zip }}
              </p>
            </div>
          </div>

          <button v-on:click="submitSeller" class="button is-fullwidth">
            Apply now
          </button>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          v-on:click="modal = !modal"
        ></button>
      </div>
    </div>

    <div
      class="modal"
      id="thank-you"
      v-bind:class="{ 'is-active': modalThankYou }"
    >
      <div
        class="modal-background"
        v-on:click="modalThankYou = !modalThankYou"
      ></div>
      <div class="modal-content">
        <div class="form-wrap">
          <img src="../assets/checkbox-circle-line.svg" alt="" />

          <p id="form-title">You're in!</p>
          <p id="form-text">
            We're diligently working to build for you an exciting new way to
            access American products and businesses.
          </p>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          v-on:click="modalThankYou = !modalThankYou"
        ></button>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios';
import { API_URL } from "@/config";

export default {
  name: 'LandingPage',
  data() {
    return {
      modal: false,
      modalThankYou: false,
      customerEmail: '',
      sellerEmail: '',
      firstName: '',
      lastName: '',
      businessName: '',
      dba: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      customerError: false,
      sellerError: false,
      errorMessage: '',
      errorArr: {},
      success: false,
    };
  },
  methods: {
    formatPhone() {
      var x = this.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.phone = !x[2]
        ? x[1]
        : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    },
    validateEmail(email) {
      return /\S+@\S+\.\S+/.test(email);
    },
    validateString(string) {
      return string.trim().length;
    },
    validateZip(zip) {
      return /^\d{5}(-\d{4})?$/.test(zip);
    },
    isObjectEmpty() {
      return Object.values(this.errorArr).every((value) => value === '');
    },
    async submitCustomer() {
      if (this.validateEmail(this.customerEmail)) {
        try {
          const response = await axios.post(`${API_URL}/customer/${this.customerEmail}`);
          this.success = true;
          this.customerError = false;
          this.errorMessage = '';
          console.log(response);
          this.modalThankYou = true;
        } catch (error) {
          this.customerError = true;
          this.success = false;
          this.errorMessage = error?.response?.data ? error.response.data : 'There was an error submitting your information, please try again';
          console.log(error);
        }
      } else {
        this.customerError = true;
        this.errorMessage = 'Please use a valid email address';
      }
    },
    async submitSeller() {
      this.errorArr = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        legalBusinessName: '',
        dba: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      };
      this.errorMessage = '';
      this.sellerError = false;

      if (!this.validateString(this.firstName)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          firstName: `First Name is required`,
        };
      }

      if (!this.validateString(this.lastName)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          lastName: `Last Name is required`,
        };
      }

      if (!this.validateString(this.sellerEmail)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          email: `Email is required`,
        };
      } else if (!this.validateEmail(this.sellerEmail)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          email: `Your email is incorrect`,
        };
      }

      if (!this.validateString(this.businessName)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          legalBusinessName: `A business name is required`,
        };
      }

      if (!this.validateString(this.address1)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          line1: `An address is required`,
        };
      }

      if (!this.validateString(this.city)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          city: `A city is required`,
        };
      }

      if (!this.validateString(this.state)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          state: `A state is required`,
        };
      }

      if (!this.validateZip(this.zip)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          zip: `A 5 digit zip is required`,
        };
      }

      if (!this.validateString(this.phone)) {
        this.sellerError = true;
        this.errorArr = {
          ...this.errorArr,
          phone: `A 10 digit phone number is required`,
        };
      }

      console.log(`error message ${this.sellerError}`);
      console.log(`error array size ${this.errorArr}`);

      if (this.sellerError === false && this.isObjectEmpty()) {
        const sellerData = {
          data: {
            firstName: `${this.firstName}`,
            lastName: `${this.lastName}`,
            businessName: `${this.businessName}`,
            dba: `${this.dba}`,
            address1: `${this.address1}`,
            address2: `${this.address2}`,
            city: `${this.city}`,
            state: `${this.state}`,
            zip: `${this.zip}`,
            phone: `${this.phone}`,
          },
        };

        try {
          const response = await axios.post(`${API_URL}/seller/${this.sellerEmail}`, sellerData);
          this.success = true;
          this.errorMessage = '';
          console.log(response);
          this.modal = false;
          this.modalThankYou = true;
        } catch (error) {
          this.success = false;
          this.customerError = true;
          this.errorMessage = error?.response?.data ? error.response.data : 'There was an error submitting your information, please try again';
          console.log(error);
        }
      }
    },
  },
};
</script>
