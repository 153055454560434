<template>
  <div class="static-page-container">
    <h1>{{ pageTitle }}</h1>
    <div v-html="pageContent"></div>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService';

export default {
  data() {
    return {
      pageContent: '',
      pageTitle: '',
    };
  },
  watch: {
    // Watch for changes to the route
    $route: {
      immediate: true, // This ensures the handler is called on initial load
      handler() {
        this.fetchPageData();
      },
    },
  },
  methods: {
    async fetchPageData() {
      const slug = this.$route.params.slug;
      try {
        const response = await ApiService.getStaticPageBySlug(slug);
        this.pageContent = response.data.data.staticPage.content;
        this.pageTitle = response.data.data.staticPage.title;
      } catch (error) {
        console.error('Failed to fetch static page:', error);
      }
    },
  },
};
</script>

<style scoped>
.static-page-container h1 {
  text-align: center;
}

.static-page-container {
  padding: 3.6rem 6rem 1.25rem 6rem;
}

@media screen and (max-width: 1280px) {
  .static-page-container {
    padding: 2.3rem 2rem 1.12rem 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .static-page-container {
    padding: 2.3rem 2.5rem 1.12rem 2.8rem;
  }
}

@media screen and (max-width: 480px) {
  .static-page-container {
    padding: 2.3rem 0.7rem 1.12rem 0.7rem;
  }
}
</style>
