<template>
  <main class="main">
    <AppHeader />
    <router-view class="flex-grow"></router-view>
    <AppFooter />
  </main>
</template>

<script>
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

* {
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-size: 16px;
  height: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

body {
  background-color: white;
}

.flex-grow {
  flex-grow: 1;
}
</style>
