import { createStore } from 'vuex';
import ApiService from '@/services/ApiService';

export default createStore({
  state: {
    categories: [],
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },
  },
  actions: {
    async fetchStaticPagesCategories({ commit }) {
      try {
        const response = await ApiService.getStaticPagesCategories();
        commit('SET_CATEGORIES', response.data.data);
      } catch (error) {
        console.error('Failed to fetch static pages:', error);
      }
    },
  },
});
