<style scoped>
footer#footer {
  background: #eeeeee;
  min-width: 100%;
  padding: 3.6rem 6rem 1.25rem 6rem;
  min-height: 300px;
  height: max-content;
  position: relative;
}

.container-footer {
  display: flex;
  gap: 0 200px;
}

@media screen and (max-width: 1280px) {
  footer#footer {
    padding: 2.3rem 2rem 1.12rem 2.5rem;
  }

  .container-footer {
    gap: 0 150px;
  }
}

@media screen and (max-width: 768px) {
  footer#footer {
    padding: 2.3rem 2.5rem 1.12rem 2.8rem;
  }

  .container-footer {
    gap: 0 15px;
  }
}

@media screen and (max-width: 480px) {
  footer#footer {
    padding: 2.3rem 0.7rem 1.12rem 0.7rem;
  }

  .container-footer {
    gap: 0 20px;
  }
}

.footer-title {
  margin-bottom: 2rem;
}

.categories-container {
  display: flex;
  justify-content: center;
  gap: 5rem;
  flex-wrap: wrap;
  margin-bottom: 60px;
  width: 100%;
}

.container-category {
  border-top: 1px solid #e5e5e5;
  text-align: center;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.category {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.category-title {
  color: #303030;
  font-family: 'SF Pro Display';
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.31rem;
  text-align: start;
}

.pages-list {
  text-align: start;
  margin-right: auto;
}

.pages-list li .custom-router-link {
  list-style-type: none;
  padding: 0;
  color: #838383 !important;
  font-family: 'SF Pro Display';
  font-size: 0.75rem;
  font-weight: 400;
  display: block;
  text-align: left;
  transition: 0.5s;
  text-transform: none;
}

.pages-list li:hover {
  background: none;
  text-decoration: underline;
}
</style>

<template>
  <footer id="footer">
    <div class="container-footer">
      <nav class="footer-navbar" role="navigation" aria-label="main navigation">
        <a class="footer-navbar-item" href="https://axsamerica.com">
          <img
            src="../assets/logo-axs-america-R_KO-horz.19fc671b 1.svg"
            alt="AXS AMERICA"
          />
        </a>
      </nav>

      <div class="categories-container">
        <div
          v-for="category in categories"
          :key="category._id"
          class="category"
        >
          <h3 class="category-title">{{ category.title }}</h3>
          <ul class="pages-list">
            <li v-for="page in category.pages" :key="page._id">
              <router-link
                :to="`/page/${page.slug}`"
                class="custom-router-link"
                >{{ page.title }}</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container-category">
      <p class="footer-title">
        Proudly Made in America &copy; {{ currentYear }} All Rights Reserved
      </p>
    </div>
  </footer>
</template>

<script>
import ApiService from '@/services/ApiService';

export default {
  data() {
    return {
      categories: [],
      currentYear: new Date().getFullYear(),
    };
  },
  created() {
    this.fetchStaticPages();
  },
  methods: {
    async fetchStaticPages() {
      try {
        const response = await ApiService.getStaticPagesCategories();
        this.categories = response.data.data;
      } catch (error) {
        console.error('Failed to fetch static pages:', error);
      }
    },
  },
};
</script>
